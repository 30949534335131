import {
    Button,
    Input,
    Row,
    Select,
    notification,
    Radio,
    Form,
    Modal,
} from "antd";
import React, { useState } from "react";
import Apicall from "../../Helper/Apicall";
import Loader from "../../Helper/Loader";
import { groupArray, subGroupArray } from "../../Data/GroupData";
import { minimumBet } from "../../Data/TemplateData";

const TargetWebsite = ({
    groupName,
    subGroupName,
    deviceId,
    afterModalClose,
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [targetWeb, setTargetWeb] = useState({
        userName: "",
        password: "",
        templateCategoryCode: "",
        groupName: groupName,
        subGroupName: subGroupName,
        templateCategoryName: "",
        minimumBet: "",
        // nominalMul:"",
        pin: "",
        url: "",
        serialNo: deviceId,
        status: "",
    });
    const [templates, setTemplates] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [subGroupData, setSubGroupData] = useState([]);

    const AddTargetWeb = async () => {
        try {
            if (
                !targetWeb.userName ||
                !targetWeb.password ||
                !targetWeb.templateCategoryName ||
                !targetWeb.pin ||
                !targetWeb.url ||
                !targetWeb.groupName ||
                !targetWeb.subGroupName
            ) {
                throw new Error("Please Fill all Value");
            } else {
                let regex = /^[a-zA-Z0-9_-]+$/;
                let check = targetWeb.userName.match(regex);

                // commented   as of now
                // if (check === null) {
                //     throw new Error(`User name Must be email id`);
                // }

                // check url validation
                let urlRegex =
                    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
                let urlCheck = targetWeb.url.match(urlRegex);

                // commented for now

                // if (urlCheck === null) {
                //     throw new Error("invalid Url");
                // }

                let payload = {
                    userName: targetWeb.userName,
                    password: targetWeb.password,
                    templateCategoryCode: targetWeb.templateCategoryCode,
                    templateCategoryName: targetWeb.templateCategoryName,
                    pin: targetWeb.pin,
                    url: targetWeb.url,
                    serialNo: targetWeb.serialNo,
                    minimumBet: targetWeb.minimumBet,
                    // nominalMul: targetWeb.nominalMul,
                    status: targetWeb.status,
                    groupName: targetWeb.groupName,
                    subGroupName: targetWeb.subGroupName,
                };
                setLoading(true);

                let response = await Apicall("post", "addTargetWeb", payload);
                if (response.success === 0) {
                    throw new Error(response.message);
                } else {
                    api.info({
                        message: "target Web Added succefully",
                        duration: 1,
                    });
                    setTargetWeb({
                        userName: "",
                        password: "",
                        templateCategoryCode: "",
                        templateCategoryName: "",
                        pin: "",
                        url: "",
                        serialNo: "",
                        status: "",
                        groupName: "",
                        subGroupName: "",
                    });
                    setLoading(false);
                    setOpen(!open);
                    afterModalClose();
                }
            }
        } catch (error) {
            api.error({ message: error.message, duration: 1.5 });
            setLoading(false);
        }
    };

    const getAllTemplate = async () => {
        let response = await Apicall("post", "getAllTemplate");
        if (response.success === 0) {
            api.error({ message: response.message });
        } else {
            setTemplates(response.data);
        }
    };

    const selectOneTemplate = (label, value) => {
        setTargetWeb({
            ...targetWeb,
            templateCategoryCode: value.value,
            templateCategoryName: value.label,
        });
    };

    const getGroupDataApi = async () => {
        // call api latter
        let data = await Apicall("post", "getAllGroups", {
            action: "groupData",
        });
        setGroupData(data.data);
    };

    const selectGroup = async (value, label) => {
        let data = await Apicall("post", "getAllGroups", {
            groupId: value,
            action: "subGroupData",
        });
        if (data.success === 1) {
            setTargetWeb({ ...targetWeb, groupName: label.label });
            setSubGroupData(data.data);
        }
    };

    const selectSubGroup = (value, label) => {
        setTargetWeb({ ...targetWeb, subGroupName: label.label });
    };

    const setStatus = (value) => {
        setTargetWeb({ ...targetWeb, status: value });
    };
    return (
        <>
            {contextHolder}
            {!open ? (
                <Button onClick={() => setOpen(!open)}> ADD WEBSITE</Button>
            ) : (
                <Modal
                    open={open}
                    onCancel={() => setOpen(!open)}
                    footer={false}
                >
                    <div>
                        <Row justify={"center"}>
                            <h4>Add Website</h4>
                        </Row>
                        <Row className="input">
                            Username :{" "}
                            <Input
                                placeholder="please provide userName"
                                value={targetWeb.userName}
                                onChange={(e) =>
                                    setTargetWeb({
                                        ...targetWeb,
                                        userName: e.target.value,
                                    })
                                }
                            />
                        </Row>
                        <Row className="input">
                            Password :{" "}
                            <Input
                                type="password"
                                value={targetWeb.password}
                                placeholder="please provide password"
                                onChange={(e) =>
                                    setTargetWeb({
                                        ...targetWeb,
                                        password: e.target.value,
                                    })
                                }
                            />
                        </Row>
                        <Row className="input">
                            Group
                            <Select
                                className="input"
                                value={targetWeb.groupName}
                                onChange={selectGroup}
                                onClick={getGroupDataApi} // make api call to fetch data of Group
                                style={{
                                    width: 1000,
                                }}
                                options={groupData}
                            />
                        </Row>
                        <Row>
                            Sub Group :{" "}
                            <Select
                                className="input"
                                value={targetWeb.subGroupName}
                                onChange={selectSubGroup}
                                onClick={getGroupDataApi} // decide latter to call api
                                style={{
                                    width: 1000,
                                }}
                                options={subGroupData}
                            />
                        </Row>
                        <Row>
                            Minimum Bet
                            <Select
                                className="input"
                                options={minimumBet}
                                style={{ width: "100%" }}
                                onChange={(value, label) => {
                                    setTargetWeb((pre) => ({
                                        ...pre,
                                        minimumBet: value,
                                    }));
                                }}
                            />
                        </Row>
                        {/* <Row>
                            Nominal Mul
                            <Input
                                onChange={(e) =>
                                    setTargetWeb((pre) => ({
                                        ...pre,
                                        nominalMul: Number(e.target.value),
                                    }))
                                }
                            />
                        </Row> */}
                        <Row>
                            Select Template :{" "}
                            <Select
                                className="input"
                                placeholder="select user Role"
                                value={targetWeb.templateCategoryName}
                                onChange={selectOneTemplate}
                                onClick={getAllTemplate}
                                style={{
                                    width: 1000,
                                }}
                                options={templates}
                            />
                        </Row>
                        <Row className="input">
                            Pin :{" "}
                            <Input
                                type="password"
                                value={targetWeb.pin}
                                placeholder="please provide Pin"
                                onChange={(e) =>
                                    setTargetWeb({
                                        ...targetWeb,
                                        pin: e.target.value,
                                    })
                                }
                            />
                        </Row>
                        <Row className="input">
                            Url :{" "}
                            <Input
                                value={targetWeb.url}
                                placeholder="please provide URL"
                                onChange={(e) =>
                                    setTargetWeb({
                                        ...targetWeb,
                                        url: e.target.value,
                                    })
                                }
                            />
                        </Row>
                        {/* <Row className="input">
                        Serial No :{" "}
                        <Input
                            value={targetWeb.serialNo}
                            placeholder="Add Serial No"
                            onChange={(e) =>
                                setTargetWeb({
                                    ...targetWeb,
                                    serialNo: e.target.value,
                                })
                            }
                        />
                    </Row> */}
                        <Row className="input">
                            Status :
                            <Select
                                className="input"
                                placeholder="select Status"
                                value={targetWeb.status}
                                onChange={setStatus}
                                style={{
                                    width: 1000,
                                }}
                                options={[
                                    {
                                        label: "Active",
                                        value: "ACTIVE",
                                    },
                                    {
                                        label: "Inactive",
                                        value: "INACTIVE",
                                    },
                                ]}
                            />
                        </Row>
                        <Row justify={"center"}>
                            <Button type="primary" onClick={AddTargetWeb}>
                                Add Website
                            </Button>
                        </Row>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default TargetWebsite;
