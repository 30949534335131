export const groupArray = [
    {
        value: "a",
        label: "GroupA",
    },
    {
        value: "b",
        label: "GroupB",
    },
    {
        value: "c",
        label: "GroupC",
    },
    {
        value: "d",
        label: "GroupD",
    },
    {
        value: "e",
        label: "GroupE",
    },
];

export const subGroupArray = [
    {
        value: "a1",
        label: "GroupA1",
        group: "a",
    },
    {
        value: "a2",
        label: "GroupA2",
        group: "a",
    },
    {
        value: "a3",
        label: "GroupA3",
        group: "a",
    },
    {
        value: "a4",
        label: "GroupA4",
        group: "a",
    },
    {
        value: "b1",
        label: "GroupB1",
        group: "b",
    },
    {
        value: "b2",
        label: "GroupB2",
        group: "b",
    },
    {
        value: "b3",
        label: "GroupB3",
        group: "b",
    },
    {
        value: "b4",
        label: "GroupB4",
        group: "b",
    },

    {
        value: "c1",
        label: "GroupC1",
        group: "c",
    },
    {
        value: "c2",
        label: "GroupC2",
        group: "c",
    },
    {
        value: "c3",
        label: "GroupC3",
        group: "c",
    },
    {
        value: "c4",
        label: "GroupC4",
        group: "c",
    },
    {
        value: "d1",
        label: "Group",
        group: "d",
    },
    {
        value: "d2",
        label: "GroupD2",
        group: "d",
    },
    {
        value: "d3",
        label: "GroupD3",
        group: "d",
    },
    {
        value: "d4",
        label: "GroupD4",
        group: "d",
    },
];

export const configData = {
    pattern: {
        "4d": [
            {
                label: "Or Bet full",
                value: "orBetFull",
            },
            {
                label: " Exclude-AAAA ",
                value: "excludeAAAA",
            },
            {
                label: " Exclude-AABB ",
                value: "excludeAABB",
            },
            {
                label: " Exclude AAxx ",
                value: "excludeAAxx",
            },
            {
                label: " Exclude xxAA ",
                value: "excludexxAA",
            },
            {
                label: " Exclude xAAx ",
                value: "excludexAAx",
            },
            {
                label: " Exclude AAAx",
                value: "excludeAAAx",
            },
            {
                label: " Exclude xAAA",
                value: "excludexAAA",
            },
        ],

        "3d": [
            {
                label: "Or Bet full",
                value: "orBetFull",
            },
            {
                label: " Exclude-AAA ",
                value: "excludeAAA",
            },
            {
                label: " Exclude AAx ",
                value: "excludeAAx",
            },
            {
                label: " Exclude xAA ",
                value: "excludexAA",
            },
        ],

        "2d": [
            {
                label: "Or Bet full",
                value: "orBetFull",
            },
            {
                label: " Exclude-AA ",
                value: "excludeAA",
            },
        ],
    },
    result: {
        "4d": [
            {
                label: "Last 5 Result",
                value: "lastfiveresult",
            },
            {
                label: "Last 10 Result",
                value: "lasttenresult",
            },
            {
                label: "Last 20 Result",
                value: "lasttwentyresult",
            },
            {
                label: "Last 30 Result",
                value: "lastthirtyresult",
            },
            {
                label: "None",
                value: "none",
            },
        ],
        "3d": [
            {
                label: "Last 5 Result",
                value: "lastfiveresult",
            },
            {
                label: "Last 10 Result",
                value: "lasttenresult",
            },
            {
                label: "Last 20 Result",
                value: "lasttwentyresult",
            },
            {
                label: "Last 30 Result",
                value: "lastthirtyresult",
            },
            {
                label: "None",
                value: "none",
            },
        ],
        "2d": [
            {
                label: "Last 5 Result",
                value: "lastfiveresult",
            },
            {
                label: "Last 10 Result",
                value: "lasttenresult",
            },
            {
                label: "Last 20 Result",
                value: "lasttwentyresult",
            },
            {
                label: "Last 30 Result",
                value: "lastthirtyresult",
            },
            {
                label: "None",
                value: "none",
            },
        ],
    },
    position: {
        "4d": [
            {
                label: "Position As (A)",
                value: "positionAsA",
                count: "1",
                checked: false,
            },
            {
                label: "Position kop (B)",
                value: "positionAsB",
                count: "1",
                checked: false,
            },
            {
                label: "Position Kepala (C)",
                value: "positionAsC",
                count: "1",
                checked: false,
            },
            {
                label: "Position Ekor (D)",
                value: "positionAsD",
                count: "1",
                checked: false,
            },
        ],
        "3d": [
            {
                label: "Position kop (B)",
                value: "positionAsB",
                count: "1",
                checked: false,
            },
            {
                label: "Position Kepala (C)",
                value: "positionAsC",
                count: "1",
                checked: false,
            },
            {
                label: "Position Ekor (D)",
                value: "positionAsD",
                count: "1",
                checked: false,
            },
        ],
        "2d": [
            {
                label: "Position Kepala (C)",
                value: "positionAsC",
                count: "1",
                checked: false,
            },
            {
                label: "Position Ekor (D)",
                value: "positionAsD",
                count: "1",
                checked: false,
            },
        ],
    },
};

export const marketOption = [
    {
        label: "CAMBODIA",
        value: "cambodia",
    },
    {
        label: "SYDNEY",
        value: "sydney",
    },
    {
        label: "CHINA",
        value: "china",
    },
    {
        label: "JAPAN",
        value: "japan",
    },
    {
        label: "SINGAPORE",
        value: "singapore",
    },
    {
        label: "GRABPOOLS",
        value: "grabpools",
    },
    {
        label: "MONGOLIA",
        value: "mongolia",
    },
    {
        label: "TAIWAN",
        value: "taiwan",
    },
    {
        label: "NUSANTARA",
        value: "nusantara",
    },
    {
        label: "HONGKONG",
        value: "hongkong",
    },
];

export const betType = [
    {
        label: "4D",
        value: "4d",
    },
    {
        label: "3D",
        value: "3d",
    },
    {
        label: "2D",
        value: "2d",
    },
];
